import Log from 'Utils/log.js';
import window from 'global/window';
import ValidateJs from 'validate-js';

$('#feedback_mobile').bind('input propertychange', function () { 
	var fizeNum = $(this).val().length;  
	if (fizeNum > 400) {    
		var char = $(this).val();    
		char = char.substr(0, 400);    
		$(this).val(char);    
		fizeNum = 400;    
		$("#texttips").html("<div  style=\"color: red;\"><span\">消息内容超出最大限制</span><span class=\"contentcount\">0</span>/400</div>");  
	}else{
		$("#texttips").html("<span class=\"contentcount\">0</span>/400 ");
	}
	$(this).parent().find('.contentcount').text(fizeNum);
});

const errorLog = new Log($('#feedbackWrap'));
const $feebackForm = $('#feedbackMobileForm');

let validator = new ValidateJs('feedbackMobileForm', [
	  {
	    name: 'content',
	    display:'反馈内容',
	    rules: 'required',
	  }
], function(error, event) {
  if (event && event.preventDefault) {
    event.preventDefault();
  } else if (event) {
    event.returnValue = false;
  }
  if(error.length > 0) {
    // 展示第一条错误信息
    errorLog.showFailLog(error[0].message, 1);
    setTimeout(function () {
    	errorLog.removeLog();
    }, 3000);
    return;
  }
  // 把错误信息去掉
  errorLog.removeLog();	  
  $.ajax({
    url: '/secure/feedback/save',
    type: 'POST',
    dataType: 'json',
    data: $feebackForm.serialize()
  })
  .done(function(result) {
    if(result.data === 'fail') {
    	alert(result.message);
      errorLog.showFailLog(result.message, 1);
      setTimeout(function () {
      	errorLog.removeLog();
      }, 3000);
    }else {
    	errorLog.showSuccessLog("问题反馈提交成功！", 1);
        setTimeout(function(){
            window.location.reload();
        },3000);
     }
  });
});

//设置表单为空显示字段
validator.setMessage('required', '%s不能为空');
