/*
* 私信通知
*/
import 'Utils/tab.js';
import window from 'global/window';

$(window).on('load', function(){
  const $msgTab = $('#msgTab');
  if($msgTab.length < 1) return;
  const tab =  $msgTab.tab();
});
