// import 'owl.carousel';
import $ from 'jquery';
import 'FakeReactModules/mobile/home/index-coupon/index-coupon.js';
 
$(document).ready(function () {
    const $slider = $('#slider');
    $slider.owlCarousel({
        singleItem: true,
        items: 1,
        loop: true,
        autoplay: true
    });
});
