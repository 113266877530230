/*
 * q取消绑定账号
 */
import ValidateJs from 'validate-js';
import window from 'global/window';
import Log from 'Utils/log.js';

$("#cancelBind").on('touchend',function(e){
  $.ajax({
    url: '/secure/user/cancel-bind?oauthId='+oauthId,
    type: 'GET',
    cache: false,
    data: null,
    processData: false,
    contentType: false
  }).done(function(json) {
    if(json.status == "success") {
        window.location.assign('/secure/user/oauth');
    } else {
        alert(json.message);
    }
  });
});