/*
* 我的课程
*/
import 'Utils/tab.js';
import window from 'global/window';

$(window).on('load', function(){
  const $myClassTab = $('#myClassTab');
  if($myClassTab.length > 0) {
    $myClassTab.tab();
  }
});
