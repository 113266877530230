/*
 * 修改密码
 */
import ValidateJs from 'validate-js';
import window from 'global/window';
import Log from 'Utils/log.js';


//需要展示错误信息在其顶部的元素
const $changePwdWrap = $('#changePwdWrap');
const errorLog = new Log($("body"));
const $changePwdForm = $('#changePwdForm');

const hasSetting = window.hasSetting;

const validRules = [{
  name: 'newPassword',
  display: '新密码',
  rules: 'required'
}, {
  name: 'comfirmPassword',
  display: '确认密码',
  rules: 'required|matches[newPassword]'
}];

if (hasSetting) {
  validRules.push({
    name: 'password',
    display: '密码',
    rules: 'required'
  });
}

let valid = new ValidateJs('changePwdForm', validRules, function(error, event) {
  if (event && event.preventDefault) {
    event.preventDefault();
  } else if (event) {
    event.returnValue = false;
  }
  if (error.length > 0) {
    // 展示第一条错误信息
    errorLog.showFailLog(error[0].message, 1);
    setTimeout(function() {
      errorLog.removeLog();
    }, 3000);
    return;
  }

  // 把错误信息去掉
  errorLog.removeLog();
  $.ajax({
    url: '/secure/user/change-password',
    type: 'POST',
    dataType: 'json',
    data: $changePwdForm.serialize()
  }).done(function(result) {
    if (result.status === 'error') {
      errorLog.showFailLog(result.message, 1);
      setTimeout(function() {
        errorLog.removeLog();
      }, 3000);
    } else {
      errorLog.showSuccessLog("密码修改成功！", 1);
      setTimeout(function() {
        window.location.reload();
      }, 3000);
    }
  });
});

valid
  .registerCallback('checkPassword', function(value) {
    const len = value.length;
    if (len < 5 || len > 16) return false;
    return /^(?![^0-9]+$)(?![^a-zA-Z]+$).+$/.test(value);
  })
  .setMessage('checkPassword', '请输入5到16位包含数字与字母的密码');

valid.setMessage('required', '%s不能为空');
valid.setMessage('matches', '%s必须与新密码一致');