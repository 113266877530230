/*
 * vip直播播放页 
 */
import ValidateJs from 'validate-js';
import window from 'global/window';
import Log from 'Utils/log.js';

$(window).on('load', function(){
	var courseId = $("#js-ppt-live-course-courseId").val();
	// 信息提示位置
	const pptVipLiveCourseLog = new Log($("body"));
	   // 开启预约提醒提示
    $("#js-ppt-live-course-startNotify").on('click',function(){
        $.post({
            url: "/secure/course-booking/"+courseId+"/add-course-booking",
            success(data){
            	window.location = "/course/"+courseId+"?fromBookingPage=Y";
            }
        });
    });

    // 取消预约提醒提示
    $("#js-ppt-live-course-cancelNotify").on('click',function(){
        $.post({
            url: "/secure/course-booking/"+courseId+"/cancel-course-booking",
            success(data){
            	 window.location = "/course/"+courseId+"?fromBookingPage=Y";
            }

        });
    });
    


});
