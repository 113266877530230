import FakeReact from 'Utils/fake-react';
import PageIndicator from 'FakeReactComponents/mobile/common/PageIndicator';
import './index.scss';

class CouponListIndicator extends FakeReact.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let children = [];
        const pageIndicator = new PageIndicator({ totalPages: this.props.totalPages, currPage: this.props.currPage });
        children.push(pageIndicator);
        this.props.children = children;
        return `<div>
            <div class="coupon-page-indicator">${this.childRoot}</div>
        </div>`;
    }
}

export default CouponListIndicator;