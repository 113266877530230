/*
 * 基本信息
 */
import ValidateJs from 'validate-js';
import window from 'global/window';
import Log from 'Utils/log.js';

//将错误信息展现在顶部位置
const $basicsWrap = $('#basicsWrap');
const basicsLog = new Log($("body"));
const $infoForm = $('#basicsForm');
var $jsBasicsIsWeixin = $("#jsBasicsIsWeixin").val();   // Y || N

// 上传头像
$("#jsBasicsFaceBtn").on("click", function(e) {
    $("#jsBasicsAvatarInput").click();
});

$("#jsBasicsAvatarInput").on("change", function(e) {
    var file = e.target.files[0]; //获取图片资源
    // 只选择图片文件
    if (!file.type.match('image.*')) {
        return false;
    }
    var reader = new FileReader();
    reader.readAsDataURL(file); // 读取文件
    // 渲染文件
    reader.onload = function(arg) {
        uploadedImage();
    }
});

function uploadedImage() {
    var form_data = new FormData();
    var file_data = $("#jsBasicsAvatarInput").prop("files")[0];

    // 把上传的数据放入form_data
    form_data.append("file", file_data);

    $.ajax({
        type: "POST", // 上传文件要用POST
        url: "/secure/upload/image",
        dataType : "json",
        crossDomain: true, // 如果用到跨域，需要后台开启CORS
        processData: false,  // 注意：不要 process data
        contentType: false,  // 注意：不设置 contentType
        data: form_data,
        success : function(json) {
            $("#jsBasicsFace").attr("src", json.data.fileUrl);
            $("#jsBasicsavatar").attr("value", json.data.relativeFilePath);
        },
        error : function(data) {
            basicsLog.showFailLog("上传失败", 1);
            setTimeout(function () {
                basicsLog.removeLog();
            }, 3000);
        }
    });
}

/*
 * 检查手机号码格式
 * @param {String} value 手机号
 * @return {Boolean}
 */
function checkPhone(value) {
    return !!value.match(/^(0|86|17951)?(13[0-9]|15[0-9]|16[0-9]|17[0-9]|18[0-9]|19[0-9]|14[0-9])[0-9]{8}$/);
}

function tryAgain(ele, times) {
    var t = times - 1;
    if(t >= 0) {
        $(ele).html(t + "秒后重试");
        setTimeout(function () {
            tryAgain(ele, t);
        }, 1000);
    }else {
        $(ele).removeAttr("disabled").html("发送验证码");
    }
}
// 点击切换验证码
$('#basicsForm').on('click', '#verificationCode', function(event) {
    this.src = '/kaptcha?' + new Date().getTime();
});

let config;
if ($jsBasicsIsWeixin == "Y") {
    // 微信平台手机号非必填
    config = [{
        name : 'username',
        display : '用户名',
        rules: 'required'
    }, {
        name: 'mobile',
        display: '手机号',
        rules : 'callback_checkPhone'
    }, {
        name: 'teacherName',
        display: '教师姓名',
        rules : 'required'
    }];
} else {
    config = [{
        name : 'username',
        display : '用户名',
        rules: 'required'
    }, {
        name: 'mobile',
        display: '手机号',
        rules : 'required|callback_checkPhone'
    }, {
        name: 'teacherName',
        display: '教师姓名',
        rules : 'required'
    }];
}

// 更改手机号显示隐藏表单
let mobileValue = $("#mobile").val();
$("#mobile").blur(function() {
    //手机号码和原值相同
    if ($(this).val() == mobileValue || $(this).val() == "") {
    	basicsLog.removeLog();
        $("#jscodeDiv").hide();
        return;
    } else {
    	basicsLog.showFailLog("请填写验证码完成更改", 1);
        setTimeout(function(){
            basicsLog.removeLog();
        },3000);
        $("#jscodeDiv").show();
    }
});

// 校验提交表单
let validator = new ValidateJs('basicsForm', config, function(error, event) {

    if (event && event.preventDefault) {
        event.preventDefault();
    } else if (event) {
        event.returnValue = false;
    }
    if(error.length > 0) {
        // 展示第一条错误信息
        basicsLog.showFailLog(error[0].message, 1);
        setTimeout(function(){
            basicsLog.removeLog();
        },3000);
        return;
    }

    // 把错误信息去掉
    basicsLog.removeLog();
    $.ajax({
        url: '/secure/user/basics',
        type: 'POST',
        dataType: 'json',
        data: $infoForm.serialize()
    }).done(function(result) {
        if(result.status === 'error') {
            basicsLog.showFailLog(result.message, 1);
            setTimeout(function(){
                basicsLog.removeLog();
            },3000);
        }else{
            basicsLog.showSuccessLog("保存基本信息成功！", 1);
            setTimeout(function(){
                window.location.reload();
            },3000);
        }
    });

});

//自定义检验规则，验证手机号码格式
validator.registerCallback('checkPhone', checkPhone).setMessage('checkPhone', '手机格式不正确');
validator.setMessage('required','%s不能为空');

// 短信验证码发送
const $jsBasicsSendKaptcha = $('#jsBasicsSendKaptcha');

// 发送短信验证码
$jsBasicsSendKaptcha.on('click', function() {
    const dataAry = $infoForm.serializeArray();
    let code = '';
    let mobile = '';
    dataAry.forEach((item) => {
        mobile = item.name !== 'mobile' ? mobile : item.value;
    code = item.name !== 'code' ? code : item.value;
});

    if(mobile === '') {
    	basicsLog.showFailLog('手机号码不能为空', 1);
        setTimeout(function(){
            basicsLog.removeLog();
        },3000);
        return;
    } else {
        if(!checkPhone(mobile)) {
        	basicsLog.showFailLog('手机号码格式不正确', 1);
            setTimeout(function(){
                basicsLog.removeLog();
            },3000);
            return;
        }
    }

    if(code === '') {
    	basicsLog.showFailLog('验证码不能为空', 1);
        setTimeout(function(){
            basicsLog.removeLog();
        },3000);
        return;
    }

    $.ajax({
        url: '/code-sign',
        type: 'GET',
        dataType: 'json',
        data: {code: code}
    }).done(function(result) {
        if(result.status === 'success') {
            $.ajax({
                url: '/send-code',
                type: 'GET',
                dataType: 'json',
                data: {
                    mobile: mobile,
                    code: code,
                    t: result.data.t,
                    h: result.data.h
                }
            }).done(function(data) {
                if(data.status === 'success') {
                    $jsBasicsSendKaptcha.attr('disabled', 'disabled').html('60秒后重试');
                    tryAgain($jsBasicsSendKaptcha, 60);
                } else {
                	basicsLog.showFailLog(data.message, 1);
                    setTimeout(function(){
                        basicsLog.removeLog();
                    },3000);
                }
            });
        } else {
        	basicsLog.showFailLog(result.message, 1);
            setTimeout(function(){
                basicsLog.removeLog();
            },3000);
        }
    });
});
