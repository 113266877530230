class SdModal {
  constructor(options) {
    this.options = options;
    this.modal = options.modal;
    this.wrap = options.wrap;
    this.cls = options.cls;
    this.close = options.close;
    this.isHide = true;
    this.init();
    this.res = null;
    this.dtd = null;
  }

  init() {
    const self = this;
    if(this.close) {
      this.modal.on('click', this.close, function(event) {
        self.options.closeEvent && self.options.closeEvent.call(this, event);
        self.hide();
      });
    }

    this.modal[0].addEventListener('webkitTransitionEnd', ()=> {
      if(this.isHide) {
        this.modal.hide();
        this.options.hideComplete && this.options.hideComplete();
      } else {
        this.options.showComplete && this.options.showComplete();
      }
    });
  }

  toggle() {
    this.isHide ? this.show() : this.hide();
  }

  show(){
    this.isHide = false;
    this.options.show && this.options.show();
    this.modal.show();
    setTimeout(()=> {
      this.wrap.addClass(this.cls);
    }, 0);
  }

  confirm() {
    this.show();
    this.dtd = $.Deferred();
    return $.when((dtd => dtd)(this.dtd));
  }

  hide() {
    this.isHide = true;
    this.options.hide && this.options.hide();
    this.wrap.removeClass(this.cls);

    if(this.dtd) {
      this.dtd.resolve(this.res);
      this.dtd = null;
    }
  }
}

export default SdModal;
