/*
 * 重新绑定
 */
import window from 'global/window';

$(window).on('load', function() {

  // 取消绑定
  $("#cancel-re-bind").on("click", function () {
    if (isPc) {
      WeixinJSBridge.call('closeWindow');
    } else {
      if ('VIP' == courseType) {
        location.href = "/course/" + courseId;
      } else {
        location.href = "/course/" + courseId + "/live";
      }
    }
  });

  // 重新绑定
  $("#confirm-re-bind").on("click", function () {
    $("#confirm-re-bind").prop("disabled", true);
    $.ajax({
      url: '/course-booking/re-bind',
      type: 'POST',
      dataType: 'json',
      data: {
        "openId": openId,
        "unionId": unionId,
        "avatar": avatar,
        "nickname": nickname,
        "sex": sex,
        "userId": userId,
        "courseId": courseId,
        "sign": sign
      }
    }).done(function (result) {
      $("#confirm-re-bind").prop("disabled", false);
      if (result.status === 'success') {
        if (isPc) {
          location.href = "/course-booking/finish"
        } else {
          if ('VIP' == courseType) {
            location.href = "/course/" + courseId;
          } else {
            location.href = "/course/" + courseId + "/live";
          }
        }
      } else {
        alert(result.message);
      }
    });

  });

});