import FakeReact from 'Utils/fake-react';
import CouponCard from 'FakeReactComponents/mobile/home/CouponCard/';
import NoMoreCard from 'FakeReactComponents/mobile/home/CouponCard/NoMoreCard';
import './index.scss';

class CouponListPage extends FakeReact.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const children = [];
        let deviceWidth = window.innerWidth;

        if (this.props.coupons && this.props.coupons[0]) {
            children.push(new CouponCard(Object.assign({}, this.props.coupons[0], { 
                handleClickReceiveBtn: this.props.handleClickReceiveBtn,
                handleClickUse: this.props.handleClickUse,
            })));
        }

        if (this.props.coupons && this.props.coupons[1]) {
            children.push(new CouponCard(Object.assign({}, this.props.coupons[1], {
                handleClickReceiveBtn: this.props.handleClickReceiveBtn,
                handleClickUse: this.props.handleClickUse,
            })));
        }

        if (this.props.coupons) {
            // 如果只有第一张，那么通常是因为列表只有单数张优惠券，此时补一个“更多活动”卡片
            if (this.props.coupons[0] && !this.props.coupons[1]) {
                children.push(new NoMoreCard());
            }
        }

        this.props.children = children;

        return `<div class="index-coupon-list-page" style="width: ${deviceWidth}px">
        ${this.childRoot}
        </div>`;
    }
}

export default CouponListPage;