$(function(){
  if (!window.isArticleList) {
    return;
  }
  
  var page = 1;
  var stop = true; //触发开关，防止多次调用事件
  fetchList(page);

  $(window).scroll(function() {  
    //当内容滚动到底部时加载新的内容 100当距离最底部100个像素时开始加载.
    if ($(this).scrollTop() + $(window).height() + 100 >= $(document).height() && $(this).scrollTop() > 100) {  
      if(stop){
        stop = false;
        page += 1; //当前要加载的页码  
        $("#articleListWrap").append('<div class="article-list-tips" id="loading">加载中...</div>');

        fetchList(page, function(more) {
          $('#loading').hide();
          if (more) {
            stop = true;
          } else {
            $("#articleListWrap").append('<div class="article-list-tips">没有更多内容啦~</div>');
          }
        });
      }
    }  
  })

  function fetchList(page, cb) {
    $.ajax({
      url: '/article/list/get',
      data: {
        page: page,
        categoryId: window.categoryId,
      },
      success: function(res) {
        if (res.code === 200) {
          var data = res.data || {};
          var paginator = data.paginator || {};
          renderArticleList(paginator.contents);
          var more = data.pageNumber < data.totalPages;
          if (cb && typeof cb === 'function') {
            cb(more);
          }
        }
      }
    });
  }

  function getImgUrl(url) {
    const imageHost = window.imageHost || undefined;
    var formatUrl = url || '/assets/images/article_default.png';
    if (formatUrl.slice(0,10) == '/resources') {
      formatUrl = '//static.vlms.cn/' + formatUrl;
    } else if(imageHost && formatUrl.slice(0,4) !== 'http' && formatUrl.slice(0,2) !== '//' && formatUrl.slice(0,7) !== '/assets' && formatUrl) {
      formatUrl = imageHost + '/thumbnail/340x213e/' + formatUrl;
    }
    return formatUrl;
  }

  function renderArticleList(articleList) {
    var len = articleList.length;
    for (var i = 0; i < len; i++) {
      var article = articleList[i];
      var previewUrl = getImgUrl(article.coverImage);
      var preview = '<img class="artile-preview" src="' + previewUrl + '">';
      var overview = '<div class="artile-overview"><h3>' 
        + article.title + '</h3><p>' 
        + article.content + '</p><span class="article-pageviews">' 
        + article.viewerCount + '</span></div>';
      var articleEle = '<a class="article-list-link" href="./article/' 
        + article.articleId + '"><div class="article-list-element">' 
        + preview 
        + overview + '</div></a>';
      $('#articleListWrap').append($(articleEle));
    }
  }
});