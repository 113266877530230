import FakeReact from 'Utils/fake-react';
import './index.scss';

var TO_USE = 'TO_USE';
var TO_RECEIVE = 'TO_RECEIVE';
var NO_LEFT = 'NO_LEFT';
var NOT_START = 'NOT_START';

class CouponCard extends FakeReact.Component {
    constructor(props) {
        super(props);
        this.state = {
            receiveStatus: props.receiveStatus,
        };
    }
 
    eventHandler() {
        var that = this;
        return {
            // 点击领取按钮
            '.index-coupon-btn-to-receive': {
                type: 'click',
                handler: () => that.props.handleClickReceiveBtn({ couponId: that.props.couponId, useType: that.props.useType })
            },
            // 点击使用按钮
            '.index-coupon-btn-to-use': {
                type: 'click',
                handler: () => that.props.handleClickUse(that.props.routeUri, that.props.useType)
            },
        }
    }

    genBtn(receiveStatus) {
        var btnClass = 'index-coupon-status-btn';
        var btn;
        if (receiveStatus === NO_LEFT) {
            return '';
        } else if (receiveStatus === NOT_START) {
            btn = '<span class="index-coupon-early-tips">未到使用时间</span>';
        } else if (receiveStatus === TO_USE) {
            btnClass += ' index-coupon-btn-to-use'
            btn = `<span class="${btnClass}">立即使用</span>`
        } else {
            btnClass += ' index-coupon-btn-to-receive'
            btn = `<span class="${btnClass}">点击领取</span>`
        }

        return `
        <div class="index-coupon-button-wrap">
            ${btn}
        </div>`
    }

    genStamp(receiveStatus) {
        if (receiveStatus === TO_USE || receiveStatus === NOT_START) {
            return '<div class="has-coupon-stamp"></div>';
        } else if (receiveStatus === NO_LEFT) {
            return '<div class="no-coupon-stamp"></div>';
        } else {
            return '';
        }
    }

    render() {
        return `
            <div class="index-coupon-wrap ${this.state.receiveStatus === NO_LEFT ? 'index-coupon-noleft-wrap-bg' : ''}">
                <div class="index-coupon-info-wrap${this.state.receiveStatus === NO_LEFT ? ' index-coupon-info-wrap-noleft' : ''}">
                    <p>
                        ${this.props.couponType === 'CASH' ? '<span class="minus-sm">￥</span><span class="minus-md">' + this.props.discount + '</span>' : '<span class="minus-md">' + this.props.discount + '</span><span class="minus-sm">折</span>'}
                    </p>

                    <p>
                        ${this.props.note}
                    </p>

                    <p>
                        满${this.props.amount}可用
                    </p>

                    ${this.genStamp(this.state.receiveStatus)}
                </div>

                ${this.genBtn(this.state.receiveStatus)}
            </div>
        `;
    }
}

export default CouponCard;