/*
 * 修改手机
 */

import ValidateJs from 'validate-js';
import window from 'global/window';
import Log from 'Utils/log.js';

/*
 * 检查手机号码格式
 * @param {String} value 手机号
 * @return {Boolean}
 */
function checkPhone(value) {
  return !!value.match(/^(0|86|17951)?(13[0-9]|15[0-9]|16[0-9]|17[0-9]|18[0-9]|19[0-9]|14[0-9])[0-9]{8}$/);
}

// 点击切换验证码
$('#changeMobileForm').on('click', '#jsMobileCodeImg', function(event) {
  this.src = '/kaptcha?' + new Date().getTime();
});

const $changeMobileForm = $('#changeMobileForm');

// 需要展示错误信息在其顶部的元素
const $changeMobileWrap = $('#changeMobileWrap');

const changeMobileLog = new Log($changeMobileWrap);

function tryAgain($ele, times) {
  if (times > 0) {
    $ele.html(times + "秒后重试");
    setTimeout(function() {
      tryAgain($ele, times - 1);
    }, 1000);
  } else {
    $ele.removeAttr("disabled").html("发送验证码");
  }
}

$changeMobileForm.on('change', function(event) {
  changeMobileLog.removeLog();
});

let regValidator = new ValidateJs('changeMobileForm', [{
  name: 'mobile',
  display: '手机号码',
  rules: 'required|numeric|callback_checkPhone',
}, {
  name: 'code',
  display: '验证码',
  rules: 'required'
}, {
  name: 'kaptcha',
  display: '短信验证码',
  rules: 'required'
}], function(error, event) {
  if (event && event.preventDefault) {
    event.preventDefault();
  } else if (event) {
    event.returnValue = false;
  }
  if (error.length > 0) {

    // 展示第一条错误信息
    changeMobileLog.showFailLog(error[0].message, 1, 3000);

    return;
  }

  // 把错误信息去掉
  changeMobileLog.removeLog();
  $.ajax({
    url: '/secure/user/change-mobile',
    type: 'POST',
    dataType: 'json',
    data: $changeMobileForm.serialize()
  }).done(function(result) {
    if (result.status === 'success') {
      changeMobileLog.showSuccessLog("手机修改成功,3秒返回原页面", 1, 3000);
    } else {
      changeMobileLog.showFailLog(result.message, 1, 3000);
    }
  });
});

regValidator
  .registerCallback('checkPhone', checkPhone)
  .setMessage('checkPhone', '手机号码格式不正确');

regValidator.setMessage('required', '%s不能为空');

regValidator
  .registerCallback('checkPassword', function(value) {
    const len = value.length;
    if (len < 5 || len > 16) return false;
    return /^(?![^0-9]+$)(?![^a-zA-Z]+$).+$/.test(value);
  })
  .setMessage('checkPassword', '请输入5到16位包含数字与字母的密码');

// 短信验证码发送
const $sendKaptcha = $('#jsMobileSendKaptcha');
$sendKaptcha.on('click', function() {
  const dataAry = $changeMobileForm.serializeArray();
  let code = '';
  let mobile = '';
  dataAry.forEach((item) => {
    mobile = item.name !== 'mobile' ? mobile : item.value;
    code = item.name !== 'code' ? code : item.value;
  });

  if (mobile === '') {
    changeMobileLog.showFailLog('手机号码不能为空', 1, 3000);
    return;
  } else {
    if (!checkPhone(mobile)) {
      changeMobileLog.showFailLog('手机号码格式不正确', 1, 3000);
      return;
    }
  }

  if (code === '') {
    changeMobileLog.showFailLog('验证码不能为空', 1, 3000);
    return;
  }

  $sendKaptcha.attr('disabled', 'disabled');

  $.ajax({
    url: '/code-sign',
    type: 'GET',
    dataType: 'json',
    data: { code: code }
  }).done(function(result) {
    if (result.status === 'success') {
      $.ajax({
          url: '/send-code ',
          type: 'GET',
          dataType: 'json',
          data: {
            mobile: mobile,
            code: code,
            t: result.data.t,
            h: result.data.h
          }
        })
        .done(function(data) {
          if (data.status === 'success') {
            tryAgain($sendKaptcha, 60);
          } else {
            changeMobileLog.showFailLog(data.message, 1, 3000);
            $sendKaptcha.removeAttr('disabled');
          }
        });

    } else {
      changeMobileLog.showFailLog(result.message, 1, 3000);
      $sendKaptcha.removeAttr('disabled');
    }
  });
});