/*
 * 忘记密码
 */

import ValidateJs from 'validate-js';
import window from 'global/window';
import Log from 'Utils/log.js';

/*
 * 检查手机号码格式
 * @param {String} value 手机号
 * @return {Boolean}
 */
function checkPhone(value) {
  return !!value.match(/^(0|86|17951)?(13[0-9]|15[0-9]|16[0-9]|17[0-9]|18[0-9]|19[0-9]|14[0-9])[0-9]{8}$/);
}

const $forgetForm = $('#forgetForm');

// 需要展示错误信息在其顶部的元素
const $forgetWrap = $('#forgetWrap');

const errorLog = new Log($forgetWrap);

function tryAgain($ele, times) {
  if (times > 0) {
    $ele.html(times + "秒后重试");
    setTimeout(function() {
      tryAgain($ele, times - 1);
    }, 1000);
  } else {
    $ele.removeAttr("disabled").html("发送验证码");
  }
}

// $forgetForm.on('change', function(event) {
//   errorLog.removeLog();
// });

let forgetValidator = new ValidateJs('forgetForm', [{
    name: 'mobile',
    display: '手机号码',
    rules: 'required|numeric|callback_checkPhone',
  },

  {
    name: 'code',
    display: '验证码',
    rules: 'required'
  },

  {
    name: 'kaptcha',
    display: '短信验证码',
    rules: 'required'
  }
], function(error, event) {
  if (event && event.preventDefault) {
    event.preventDefault();
  } else if (event) {
    event.returnValue = false;
  }

  if (error.length > 0) {
    errorLog.showFailLog(error[0].message, 1, 3000);
    return;
  }

  $.ajax({
    url: '/forget',
    type: 'POST',
    dataType: 'json',
    data: $forgetForm.serialize()
  }).done(function(result) {
    if (result.status === 'success') {
      window.location.assign('/password-reset?token=' + result.data);
    } else {
      errorLog.showFailLog(result.message, 1, 3000);
    }
  });
});

forgetValidator
  .registerCallback('checkPhone', checkPhone)
  .setMessage('checkPhone', '手机号码格式不正确');

forgetValidator.setMessage('required', '%s不能为空');

// 短信验证码发送

const $forgetSendKaptcha = $('#forgetSendKaptcha');

$forgetSendKaptcha.on('click', function() {
  const dataAry = $forgetForm.serializeArray();
  let code = '';
  let mobile = '';
  dataAry.forEach((item) => {
    if (item.name === 'mobile') mobile = item.value;
    if (item.name === 'code') code = item.value;
  });
  console.log(code, mobile);

  if (mobile === '') {
    errorLog.showFailLog('手机号码不能为空', 1, 3000);
    return;
  } else {
    if (!checkPhone(mobile)) {
      errorLog.showFailLog('手机号码格式不正确', 1, 3000);
      return;
    }
  }

  if (code === '') {
    errorLog.showFailLog('验证码不能为空', 1, 3000);
    return;
  }

  $forgetSendKaptcha.attr('disabled', 'disabled');

  $.ajax({
      url: '/code-sign',
      type: 'GET',
      dataType: 'json',
      data: { code: code }
    })
    .done(function(result) {
      if (result.status === 'success') {
        $.ajax({
            url: '/send-code ',
            type: 'GET',
            dataType: 'json',
            data: {
              mobile: mobile,
              code: code,
              t: result.data.t,
              h: result.data.h
            }
          })
          .done(function(data) {
            if (data.status === 'success') {
              tryAgain($forgetSendKaptcha, 60);
            } else {
              errorLog.showFailLog(data.message, 1, 3000);
              $forgetSendKaptcha.removeAttr('disabled');
            }
          });

      } else {
        errorLog.showFailLog(result.message, 1, 3000);
        $forgetSendKaptcha.removeAttr('disabled');
      }
    });

});

/*
 * 密码修改
 */
const SEARCH = window.location.search;
const $resetWrap = $('#resetWrap');
const $resetFrom = $('#resetForm');
const resetLog = new Log($resetWrap);

// 点击切换验证码
$('#codeImg').on('click', function(event) {
  this.src = '/kaptcha?' + new Date().getTime();
});

// $resetFrom.on('change', function(event) {
//   errorLog.removeLog();
// });

let resetValidator = new ValidateJs('resetForm', [{
  name: 'password',
  display: '密码',
  rules: 'required|callback_checkPassword'
}, {
  name: 'password_confirm',
  display: '确认密码',
  rules: 'required|matches[password]'
}], function(error, event) {
  if (event && event.preventDefault) {
    event.preventDefault();
  } else if (event) {
    event.returnValue = false;
  }
  if (error.length > 0) {

    // 展示第一条错误信息
    resetLog.showFailLog(error[0].message, 1, 3000);
    return;
  }

  // 把错误信息去掉
  // resetLog.removeLog();

  let data = $resetFrom.serializeArray();
  const parm = SEARCH.length > 0 ? SEARCH.substring(1) : '';
  const itemAry = parm.split('&');
  itemAry.forEach(function(that, index, array) {
    var ary = that.split('=');
    data.push({
      name: ary[0],
      value: ary[1]
    });
  });
  const upobj = {};
  data.forEach(function(that, index, array) {
    upobj[that.name] = that.value;
  });
  $.ajax({
      url: '/password-reset',
      type: 'POST',
      dataType: 'json',
      data: upobj
    })
    .done(function(result) {
      if (result.status === 'success') {
        resetLog.showSuccessLog('密码修改成功', 1);
        setTimeout(() => {
          window.location.assign('/login');
        }, 3000);
      } else {
        resetLog.showFailLog(result.message, 1, 3000);
      }
    });

});

resetValidator.setMessage('required', '%s不能为空');
resetValidator.setMessage('matches', '两次密码输入不一致');

resetValidator
  .registerCallback('checkPassword', function(value) {
    const len = value.length;
    if (len < 5 || len > 16) return false;
    return /^(?![^0-9]+$)(?![^a-zA-Z]+$).+$/.test(value);
  })
  .setMessage('checkPassword', '请输入5到16位包含数字与字母的密码');