/*
 * 发送私信
 */

import ValidateJs from 'validate-js';
import window from 'global/window';
import Log from 'Utils/log.js';


const $pmsgForm = $('#pmsgForm');

// 需要展示错误信息在其顶部的元素
const $pmsgWrap = $('body');

const regLog = new Log($pmsgWrap);

$pmsgForm.on('change', function(event) {
    regLog.removeLog();
});

let regValidator = new ValidateJs('pmsgForm', [
    {
        name: 'content',
        display: '发送内容',
        rules: 'required'
    }
], function(error, event) {
    if (event && event.preventDefault) {
        event.preventDefault();
    } else if (event) {
        event.returnValue = false;
    }
    if(error.length > 0) {
        // 展示第一条错误信息
        regLog.showFailLog(error[0].message);
        return;
    }

    // 把错误信息去掉
    regLog.removeLog();
    $.ajax({
        url: $pmsgForm.attr("action"),
        type: 'POST',
        dataType: 'json',
        data: $pmsgForm.serialize()
    }).done(function(result) {
        console.log(result);
        if(result.status === 'success') {
            location.reload();
        } else {
            regLog.showFailLog(result.message);
        }
    });
});

regValidator.setMessage('required', '%s不能为空');

// 定位到底部
if ($("#pmsgWrap").length > 0) {
    $("#pmsgWrap").scrollTop( $("#pmsgWrap")[0].scrollHeight );
}
