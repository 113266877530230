const $h5pay = $("#h5-pay-form");

$(".pay-way-button").on('click', function () {
  var way = $(this).data("way");
  if ($(this).hasClass("un-choose-btn")) {
    $(this).removeClass("un-choose-btn");
    $(this).addClass("choose-btn");
    if (way == "weixin") {
      $("#pur-ali-pay").removeClass("choose-btn");
      $("#pur-ali-pay").addClass("un-choose-btn");
      if (isRePay) {
        $h5pay.attr('action', "/wxpay/h5/repay");
      } else {
        $h5pay.attr('action', "/wxpay/h5");
      }
    } else {
      $("#pur-wx-pay").removeClass("choose-btn");
      $("#pur-wx-pay").addClass("un-choose-btn");
      if (isRePay) {
        $h5pay.attr('action', "/alipay/repay");
      } else {
        $h5pay.attr('action', "/alipay");
      }
    }
  }
});


$(".js-h5-pay").on('click',function(){
  $h5pay.submit();
});

$(".re-pay-again-btn").on('click', function () {
  $h5pay.submit();
});
