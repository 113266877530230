import FakeReact from 'Utils/fake-react';
import './index.scss';

class PageIndicator extends FakeReact.Component {
    constructor(props) {
        super(props);
    }

    lineWidth() {
        let width = (80 - (this.props.totalPages - 1) * 4) / this.props.totalPages;
        if (width > 0) {
            return width;
        }
        return 1;
    }

    genLines() {
        let lineStr = '';
        for (let i = 0; i < this.props.totalPages; i++) {
            lineStr += `<div class="page-indicator-line${i === this.props.currPage ? ' page-indicator-active-line' : ''}" style="width: ${this.lineWidth()}px"></div>`;
        }

        return lineStr;
    }

    render() {
        return `<div>
            <div class="page-indicator">
                ${this.genLines()}
            </div>
        </div>`;
    }
}

export default PageIndicator;