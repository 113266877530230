import 'Utils/tab.js';
import window from 'global/window';

$(window).on('load', function(){
    const talkBtn = $('#talkBtn');
    const $openTab = $('#openTab')
    if($openTab.length > 0) {
        $openTab.tab({
            talkin() {
                talkBtn.show();
            },
            talkout() {
                talkBtn.hide();
            }
        });
    }

    $(".class-likebtn").on("touchend", function(){
    	 var courseId = $("#courseId").val();
    	if($(this).hasClass("NoLogin")){
    	    window.location.href ="/login?redirectUrl=/course/"+courseId;
    		return false;
    	}
        $.post("/secure/my-course/favor-course", {courseId : courseId}, function(json){
            if(json.status == "success") {
                if(json.data == "favor"){
                    $(".class-likebtn").addClass("like");
                }else{
                    $(".class-likebtn").removeClass("like");
                }
            }
        });
        return false;
    });


});
