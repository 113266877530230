/*
 * 注册
 */

import ValidateJs from 'validate-js';
import window from 'global/window';
import Log from 'Utils/log.js';

/*
 * 检查手机号码格式
 * @param {String} value 手机号
 * @return {Boolean}
 */
function checkPhone(value) {
  return !!value.match(/^(0|86|17951)?(13[0-9]|15[0-9]|16[0-9]|17[0-9]|18[0-9]|19[0-9]|14[0-9])[0-9]{8}$/);
}

// 点击切换验证码
$('#regForm').on('click', '#jsRegisterCodeImg', function(event) {
  this.src = '/kaptcha?' + new Date().getTime();
});

const $regForm = $('#regForm');

// 需要展示错误信息在其顶部的元素
const $regWrap = $('#regWrap');

const regLog = new Log($regWrap);

function tryAgain($ele, times) {
  if (times > 0) {
    $ele.html(times + "秒后重试");
    setTimeout(function() {
      tryAgain($ele, times - 1);
    }, 1000);
  } else {
    $ele.removeAttr("disabled").html("发送验证码");
  }
}

$regForm.on('change', function(event) {
  regLog.removeLog();
});

let regValidator = new ValidateJs('regForm', [{
  name: 'mobile',
  display: '手机号码',
  rules: 'required|numeric|callback_checkPhone',
}, {
  name: 'password',
  display: '密码',
  rules: 'required|callback_checkPassword'
}, {
  name: 'captcha',
  display: '验证码',
  rules: 'required'
}, {
  name: 'code',
  display: '短信验证码',
  rules: 'required'
}], function(error, event) {
  if (event && event.preventDefault) {
    event.preventDefault();
  } else if (event) {
    event.returnValue = false;
  }
  if (error.length > 0) {

    // 展示第一条错误信息
    regLog.showFailLog(error[0].message, 1, 2000);
    return;
  }

  if(status == 'unchecked'){
      $("#uncheckedtip").show().delay(3000).hide(300);
      return;
  }

  // 把错误信息去掉
  regLog.removeLog();
  $.ajax({
    url: '/register',
    type: 'POST',
    dataType: 'json',
    data: $regForm.serialize()
  }).done(function(result) {
    if (result.status === 'success') {
      window.location.assign('/login');
    } else {
      regLog.showFailLog(result.message, 1, 2000);
    }
  });
});

regValidator
  .registerCallback('checkPhone', checkPhone)
  .setMessage('checkPhone', '手机号码格式不正确');

regValidator.setMessage('required', '%s不能为空');

regValidator
  .registerCallback('checkPassword', function(value) {
    const len = value.length;
    if (len < 5 || len > 16) return false;
    return /^(?![^0-9]+$)(?![^a-zA-Z]+$).+$/.test(value);
  })
  .setMessage('checkPassword', '请输入5到16位包含数字与字母的密码');

// 短信验证码发送

const $sendKaptcha = $('#jsRegisterSendKaptcha');
const $readStatus = $('#readRadio');

const regMobile = $('#regMobile');
regMobile.on('blur', () => {
  const val = regMobile.val().trim();
  if (!val || !checkPhone(val)) return;
  $.ajax({
      url: '/validate-mobile',
      type: 'POST',
      dataType: 'json',
      data: {
        mobile: val
      }
    })
    .done(function(res) {
      if (!res) regLog.showFailLog('该号码已经注册过', 1, 2000);
    });

});

$sendKaptcha.on('click', function() {
  const dataAry = $regForm.serializeArray();
  let code = '';
  let mobile = '';
  dataAry.forEach((item) => {
    mobile = item.name !== 'mobile' ? mobile : item.value;
    code = item.name !== 'captcha' ? code : item.value;
  });

  if (mobile === '') {
    regLog.showFailLog('手机号码不能为空', 1, 2000);
    return;
  } else {
    if (!checkPhone(mobile)) {
      regLog.showFailLog('手机号码格式不正确', 1, 2000);
      return;
    }
  }

  if (!allowNoCode && code === '') {
    regLog.showFailLog('验证码不能为空', 1, 2000);
    return;
  }

  $sendKaptcha.attr('disabled', 'disabled');

  $.ajax({
    url: '/code-sign',
    type: 'GET',
    dataType: 'json',
    data: {
      code: code
    }
  }).done(function(result) {
    if (result.status === 'success') {
      $.ajax({
          url: '/send-code ',
          type: 'GET',
          dataType: 'json',
          data: {
            mobile: mobile,
            code: code,
            t: result.data.t,
            h: result.data.h
          }
        })
        .done(function(data) {
          if (data.status === 'success') {
            tryAgain($sendKaptcha, 60);
          } else {
            regLog.showFailLog(data.message, 1, 2000);
            $sendKaptcha.removeAttr('disabled');
          }
        });
    } else {
      regLog.showFailLog(result.message, 1, 2000);
      $sendKaptcha.removeAttr('disabled');
    }
  });

});

var status = 'unchecked';
$('.schh').addClass('scqx');
$readStatus.on('click', function() {
    if(status == 'unchecked'){
        status = 'checked';
        $('.schh').removeClass('scqx');
    }
    else{
        status = 'unchecked';
        $('.schh').addClass('scqx');
    }
});

