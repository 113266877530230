import $ from 'jquery';
import FakeReact from 'Utils/fake-react';
import CouponModule from 'FakeReactModules/mobile/home/index-coupon/CouponModule';

const mount = FakeReact.mount;

$(function () {
    if(window.location.pathname === '/' || window.location.pathname === '/index') {
        // 因为目前移动端很多页面js都打包在schoo.min.js，所以要判断是否首页，再挂载首页领券模块
        const couponModuleEl = document.querySelector('.index-coupon-root');
        if(couponModuleEl) {
            mount(new CouponModule(), couponModuleEl);
        }
    }
});