$(function(){
  //表情
  $('#facemain').emotions();
  $('#faceicon').on('touchend',function(){
    if($(this).attr('disabled')){
      return;
    }
    $(this).toggleClass('faceicon-focus');
    $('.js-live-course-chat-contain').toggleClass('faceshow');
    $('#js-live-course-facewrp').toggle();
    $('#switch-audio-video').toggleClass('switch-audio-video-show');
  })
  $('body').on('touchend',function(e){
    if(!$(e.target).hasClass('faceicon') && $(e.target).parents('.facewrp').length==0){
      $('.faceicon').removeClass('faceicon-focus');
      $('.js-live-course-chat-contain').removeClass('faceshow');
      $('#js-live-course-facewrp').hide();
      $('#chatipt').blur();
      if($(e.target).hasClass('chatipt')){
        $(e.target).focus();
      }
    }
  })
})