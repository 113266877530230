/*
 * 显示错误信息
 */

class Log {
  /*
   * @param {Element} wrap 需要显示错误信息元素
   */
  constructor(wrap = 'body') {
    this.wrap = $(wrap);
    this.failwrap = Log.create('fail');
    this.successwrap = Log.create('success');
    this.clock = null;
  }

  // 创建错误信息元素
  static create(type) {
    var $div;
    if (type == "success") {
      $div = $('<div />').css({
        position: 'absolute',
        top: '0',
        left: '0',
        fontSize: '13px',
        width: '100%',
        color: '#ffffff',
        textAlign: 'center',
        backgroundColor: '#8bc34a',
        borderBottom: '1px solid #8bc34a',
        lineHeight: '40px',
        zIndex: 2000
      });
    } else if (type == "fail") {
      $div = $('<div />').css({
        position: 'absolute',
        top: '0',
        left: '0',
        fontSize: '13px',
        width: '100%',
        color: '#e65100',
        textAlign: 'center',
        backgroundColor: '#fffecc',
        borderBottom: '1px solid #cccba3',
        lineHeight: '40px',
        zIndex: 2000
      });
    }
    return $div;
  }

  /*
   * 展示错误信息
   * @param {String} txt 错误信息
   */
  showSuccessLog(txt, fix = true, time, cb) {
    clearTimeout(this.clock);
    if (fix) {
      this.successwrap.css({
        position: 'fixed'
      });
    } else {
      this.successwrap.css({
        position: 'absolute'
      });
    }
    this.successwrap.html(txt);
    this.wrap.append(this.successwrap);

    if (time) {
      this.clock = setTimeout(() => {
        this.removeLog();
        if (typeof cb === 'function') {
          cb();
        }
      }, time);
    }
  }

  /*
   * 展示错误信息
   * @param {String} txt 错误信息
   */
  showFailLog(txt, fix = true, time, cb) {
    clearTimeout(this.clock);
    if (fix) {
      this.failwrap.css({
        position: 'fixed'
      });
    } else {
      this.failwrap.css({
        position: 'absolute'
      });
    }
    this.failwrap.html(txt);
    this.wrap.append(this.failwrap);
    if (time) {
      this.clock = setTimeout(() => {
        this.removeLog();
        if (typeof cb === 'function') {
          cb();
        }
      }, time);
    }
  }

  // 移除错误信息
  removeLog() {
    this.failwrap.remove();
    this.successwrap.remove();
  }
}

export default Log;