/*
 * 登录
 */
import ValidateJs from 'validate-js';
import window from 'global/window';
import Log from 'Utils/log.js';

$(window).on('load', function() {
  /*
   * 检查手机号码格式
   * @param {String} value 手机号
   * @return {Boolean}
   */
  function checkPhone(value) {
    return !!value.match(/^(0|86|17951)?(13[0-9]|15[0-9]|16[0-9]|17[0-9]|18[0-9]|19[0-9]|14[0-9])[0-9]{8}$/);
  }

  /*
   * 生成图片验证码验证
   * @return {String} 返回图片验证码模板
   */
  function createLoginFormKaptcha() {
    return `<div class="from-group from-spe">
      <div>
        <input type="text" class="form-input" name="code" placeholder="验证码">
      </div>
      <div>
        <span><img src="/kaptcha?${new Date().getTime()}" id="jsLoginCodeImg" alt="图片验证码" /></span>
      </div>
    </div>`;
  }

  // 点击切换验证码
  $('#loginForm, #mobileNumLoginForm').on('click', '#jsLoginCodeImg', function(event) {
    this.src = '/kaptcha?' + new Date().getTime();
  });

  // 需要展示错误信息在其顶部的元素
  const $loginWrap = $('#loginWrap');

  // 初始化信息显示
  const errorLog = new Log($loginWrap);

  const $loginForm = $('#loginForm');
  const $mobileNumLoginForm = $('#mobileNumLoginForm');
  const redirectUrl = $('#redirectUrl').val();

  // 图片验证码显示区域
  const $loginCode = $('#loginCode');

  // 微信绑定账号不能登录提示
  if ($("#js-login-weixinClose").val() == 'Y') {
    errorLog.showFailLog('微信绑定的账号被禁用', 1);
    setTimeout(function() {
      errorLog.removeLog();
    }, 3000);
  }

  $('#loginForm, #mobileNumLoginForm').on('change', function(event) {
    errorLog.removeLog();
  });

  let validator = new ValidateJs('loginForm', [{
    name: 'username',
    display: '账号',
    //rules: 'required|numeric|callback_checkPhone',
    rules: 'required',
  }, {
    name: 'password',
    display: '密码',
    rules: 'required'
  }], function(error, event) {
    if (event && event.preventDefault) {
      event.preventDefault();
    } else if (event) {
      event.returnValue = false;
    }
    if (error.length > 0) {

      // 展示第一条错误信息
      errorLog.showFailLog(error[0].message, 1);
      setTimeout(function() {
        errorLog.removeLog();
      }, 3000);
      return;
    }

    // 把错误信息去掉
    errorLog.removeLog();
    $.ajax({
        url: '/ajax/login',
        type: 'POST',
        dataType: 'json',
        data: $loginForm.serialize()
      })
      .done(function(result) {
        if (result.data === 'fail') {
          errorLog.showFailLog(result.message, 1);
          setTimeout(function() {
            errorLog.removeLog();
          }, 3000);
        } else if (result.data === 'errorCount' || result.data === 'kaptch') {

          // 若当前没验证码则显示，若存在不需重新添加
          if (!$loginCode.data('set')) {
            $loginCode.html(createLoginFormKaptcha()).data('set', true);
          }
          errorLog.showFailLog(result.message, 1);
          setTimeout(function() {
            errorLog.removeLog();
          }, 3000);
        } else if (result.data === 'close') {
          errorLog.showFailLog(result.message, 1);
          setTimeout(function() {
            errorLog.removeLog();
          }, 3000);
        } else {

          // 若存在上级页面则调到上级页面，反之到主页
          window.location.assign(redirectUrl ? redirectUrl : '/index');
        }
      });
  });
  // 设置表单为空显示字段
  validator.setMessage('required', '%s不能为空');
  // 自定义检验规则，验证手机号码格式
  validator
    .registerCallback('checkPhone', checkPhone)
    .setMessage('checkPhone', '手机格式不正确');


  let mobileNumValidator = new ValidateJs('mobileNumLoginForm', [{
    name: 'mobile',
    display: '手机号码',
    rules: 'required',
  }, {
    name: 'code',
    display: '验证码',
    rules: 'required'
  }], function(error, event) {
    if (event && event.preventDefault) {
      event.preventDefault();
    } else if (event) {
      event.returnValue = false;
    }
    if (error.length > 0) {
      // 展示第一条错误信息
      errorLog.showFailLog(error[0].message, 1);
      setTimeout(function() {
        errorLog.removeLog();
      }, 3000);
      return;
    }

    // 把错误信息去掉
    errorLog.removeLog();
    $.ajax({
        url: '/ajax/login-mobile',
        type: 'POST',
        dataType: 'json',
        data: $mobileNumLoginForm.serialize()
      })
      .done(function(result) {
        if (result.data === 'success') {
          // 若存在上级页面则调到上级页面，反之到主页
          window.location.assign(redirectUrl ? redirectUrl : '/index');
        } else {
          errorLog.showFailLog(result.message, 1);
          setTimeout(function() {
            errorLog.removeLog();
          }, 3000);
        }
      });
  });
  // 设置表单为空显示字段
  mobileNumValidator.setMessage('required', '%s不能为空');
  // 自定义检验规则，验证手机号码格式
  mobileNumValidator
    .registerCallback('checkPhone', checkPhone)
    .setMessage('checkPhone', '手机格式不正确');


  const $jsLoginSendKaptcha = $('#jsLoginSendKaptcha');

  $jsLoginSendKaptcha.on('click', function() {
    const dataAry = $mobileNumLoginForm.serializeArray();
    let mobile = '';
    dataAry.forEach((item) => {
      mobile = item.name !== 'mobile' ? mobile : item.value;
    });

    if (mobile === '') {
      errorLog.showFailLog('手机号码不能为空', 1, 2000);
      return;
    } else {
      if (!checkPhone(mobile)) {
        errorLog.showFailLog('手机号码格式不正确', 1, 2000);
        return;
      }
    }

    $.ajax({
        url: '/login/send-code',
        type: 'GET',
        dataType: 'json',
        data: {
          mobile: mobile,
        }
      })
      .done(function(data) {
        if (data.status === 'success') {
          $jsLoginSendKaptcha.attr('disabled', 'disabled').html('60秒后重试');
          tryAgain($jsLoginSendKaptcha, 60);
        } else {
          errorLog.showFailLog(data.message, 1, 2000);
        }
      });
  });

  function tryAgain(ele, times) {
    var t = times - 1;
    if (t >= 0) {
      $(ele).html(t + "秒后重试");
      setTimeout(function() {
        tryAgain(ele, t);
      }, 1000);
    } else {
      errorLog.removeLog();
      $(ele).removeAttr("disabled").html("获取验证码");
    }
  }
});

$('#closeReLoginModal').on('click', function() {
  $(this).parent().parent().parent().remove();
});