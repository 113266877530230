/*
 * vip直播播放页 
 */
import window from 'global/window';
import ClipboardJS from 'clipboard';

$(window).on('load', function(){

  // 预约提醒
  $("#notify").on('click', function() {
    if (isWeixin) {
      if ($(this).data('type') === 'start') {
        window.location = '/secure/course-booking/' + courseId + '/add-course-booking/wx';
      } else {
        $.post({
          url: '/secure/course-booking/' + courseId + '/cancel-course-booking',
          success(data) {
            window.location = '/course/' + courseId + '/live';
          }
        });
      }
    } else {
      $.post({
        url: ($(this).data('type') === 'start' ?
          '/secure/course-booking/' + courseId + '/add-course-booking'
          : '/secure/course-booking/' + courseId + '/cancel-course-booking'),
        success(data) {
          window.location = '/course/' + courseId + '/live';
        }
      });
    }
  });

  var checkIsBooking = null;
  var authUrl = null;

  // 复制链接
  new ClipboardJS('#copy-booking-url', {
    text: function() {
      if (null != authUrl) {
        $(".booking-tip").html("复制成功");
        $(".booking-tip").show();
        setTimeout(function () {
          $(".booking-tip").hide();
        }, 1000);
        return authUrl;
      }
      clearIsBookingInterval();
      return auth();
    }
  });

  /**
   * 请求授权地址
   */
  var auth = function() {
    $.ajax({
      type : "POST",
      url : "/secure/course-booking/" + courseId + "/add-course-booking",
      async : false,
      success : function (json) {
        if (json.status == "success") {
          if (json.data.type == "auth") {
            checkBooking(courseId);
            $(".booking-tip").html("复制成功");
            $(".booking-tip").show();
            setTimeout(function () {
              $(".booking-tip").hide();
            }, 1000);
            authUrl = json.data.authUrl;
          } else {
            window.location.reload();
          }
        } else {
          $(".booking-tip").html("复制失败");
          $(".booking-tip").show();
          setTimeout(function () {
            $(".booking-tip").hide();
          }, 1000);
        }
      }
    });
    return authUrl;
  }

  /**
   * 查询预约状态的定时器
   * @param courseId 课程ID
   */
  function checkBooking(courseId) {
    checkIsBooking = setInterval(function() {
      $.post("/secure/course-booking/" + courseId + "/is-booking",
        function (json) {
          if (json.status == "success" && json.data == "waiting") {
            clearIsBookingInterval();
            window.location.reload();
          }
        }, 'json');
    }, 5000);
    // 半小时后清除该定时器
    setTimeout(function(){
      clearIsBookingInterval();
    }, 30 * 60 * 1000);
  }

  /**
   * 清除查询预约状态的定时器
   */
  function clearIsBookingInterval() {
    authUrl = null;
    if (null != checkIsBooking) {
      window.clearInterval(checkIsBooking);
      checkIsBooking = null;
    }
  }

});
