import FakeReact from 'Utils/fake-react';
import Modal from  '../Modal';
import VipModalContent from '../VipModalContent';
 
var Component = FakeReact.Component;

class VipModal extends Component {
    render() {
        const children = [];
        children.push(new Modal({
            children: new VipModalContent({
                handleClickCloseVipModal: this.props.handleClickCloseVipModal,
            }),
            close: this.props.handleClickCloseVipModal,
            show: this.props.show,
            style: {
                height: {
                    unit: 'rem',
                    number: 7.5
                }
            }
        }));

        this.props.children = children;

        return `
        <div>
            ${this.childRoot}
        </div>
        `;
    }
}

export default VipModal;