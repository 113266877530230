import document from 'global/document';
import window from 'global/window';
import SdModal from './modal';

const $navbar = $('#navbar');
const $html = $('html');
const $search = $('#search');
const $navStop = $('#navStop');
const $tabBtn = $('.js-tabBtn');

const modalArray = [];

/*
 * 导航菜单
 */
$tabBtn.each(function(index) {
  const $this = $(this);
  const dataClass = $this.data('class');
  const $target = $(`#${$this.data('target')}`);
  const $main = $target.children('div');
  $this.data('index', index);
  const modal = new SdModal({
    modal: $main,
    wrap: $target,
    cls: 'toggle-transform',
    hideComplete() {
      $html.removeClass(dataClass);
    },

    show() {
      $html.addClass(dataClass);
    }
  });
  modalArray.push(modal);

  $this.on('click', () => {
    let hasShowModal = false;
    if (modal.isHide) {
      modalArray.forEach(item => {
        if (!item.isHide) {
          hasShowModal = true;
          item.hide();
        }
      });
    }

    if (hasShowModal) {
      setTimeout(() => {
        modal.toggle();
      }, 300);
    } else {
      modal.toggle();
    }
  });
  $target.on('click', modal.hide.bind(modal))

  // 取消导航栏冒泡防止点击收回菜单
  $main.on('click', function(event) {
    event.stopPropagation();
  });
});

/*
 * 搜索框控制
 */

$('#searchWrap').on('click', () => {
  $html.addClass('searchin');
  $search.focus();
});

$navStop.on('click', (event) => {
  event.stopPropagation();
});

$navbar.on('click', () => {
  $search.blur();
  $html.removeClass('searchin');
});

// 搜索
const $searchBtn = $('.nav-search-btn');

$searchBtn.on('click', function(event) {
  var keyword = $('#search').val();
  if (0 != keyword.length) {
    window.location = "/course/search?keyword=" + keyword;
  }
});

// 二级菜单
$('.js-mainmenu').on('click', (e) => {
  if (e.target.nodeName === 'A') {
    return;
  }
  $(e.target).closest('.js-mainmenu').toggleClass('nav-show-submenu');
});