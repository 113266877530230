import FakeReact from 'Utils/fake-react';
import './index.scss';
 
class CouponList extends FakeReact.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let link = this.props.link ? `<a href="${this.props.link.address}">${this.props.link.name}</a>` : '';
        return `<h2 class="index-section-title">${this.props.title}${link}</h2>`;
    }
}

export default CouponList;