import 'MobileCss/school.scss';

import 'jquery';

import 'owl.carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';

// 导航栏

import 'Utils/navbar.js';

// 主页
import './controller/home/home.js';
import './controller/home/articleList.js';

// 课程列表
// import './controller/front/coursePage/courselist.js';

// vip、直播、点播课程页公共模块
import './controller/front/coursePage/coursePage.js';

// 教师个人页
import './controller/front/user/teacherinfo.js';

// 我的课程
import './controller/secure/myCourse/myCourse.js';

// 通知私信
import './controller/front/user/info.js';

// 登录
import './controller/front/login/login.js';

// 注册
import './controller/front/register/register.js';

// 忘记密码
import './controller/front/login/forget.js';

// 修改密码
import './controller/secure/user/changePwd.js';

// 基本信息
import './controller/secure/user/basics.js';

//绑定账号
import './controller/secure/user/bind.js';

// 发送私信
import './controller/secure/message/chat.js';

// vip课程播放页
// import './controller/front/coursePage/vipCourse.js';

//vip直播播放页
import './controller/front/coursePage/vip-live-course.js';

//vip直播ppt播放页
import './controller/front/coursePage/ppt-vip-live-course.js';

//公开课直播ppt播放页
import './controller/front/coursePage/ppt-live-course.js';

//订单管理
// import './controller/secure/myOrder/course-order.js';

//表情插件
import 'Utils/emotions-n.js';

//表情生成
import 'Utils/chat.main.js';

//修改手机
import './controller/secure/user/changeMobile.js';

//手机端反馈收集
import './controller/secure/feedback/feedback_mobile.js'
// 直播课
// import './controller/front/coursePage/liveCourse.js';

//支付页面
import './controller/secure/pay/pay.js'

// 重新绑定
import './controller/front/courseBooking/reBindTip.js'
