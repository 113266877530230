import FakeReact from 'Utils/fake-react';
import CouponList from 'FakeReactComponents/mobile/home/CouponList';
import VipModal from 'FakeReactComponents/mobile/common/VipModal';  // 已是vip对话框
import IndexSectionTitle from 'FakeReactComponents/mobile/common/IndexSectionTitle';
import CouponListIndicator from 'FakeReactComponents/mobile/home/CouponListIndicator';
import Popup from 'FakeReactComponents/pc/common/Popup';
import './index.scss';

const TO_USE = 'TO_USE';
const NO_LEFT = 'NO_LEFT';
var NOT_START = 'NOT_START';

$.ajaxSetup({
    cache: false
});

class CouponModule extends FakeReact.Component {
    constructor(props) {
        super(props);
        this.state = {
            currPage: 0,  // 优惠券当前页(2张/页)
            popupTimer: null,  // 领券弹出通知的消失定时器
            showGetCouponPopup: false,  // 是否显示领券消息
            getCouponMsg: '',  // 存放领券后的提示
            showVipModal: false,  // 是否显示 vip 无需再次购买的对话框
            list: [],  // 优惠券列表
        }
    }

    componentDidMount() {
        // 若登陆前点击领券，获取完所有优惠券后再调用领券接口
        this.fetchCoupons({
            callback: () => {
                const beforeLoginCouponStr = window.localStorage.getItem('beforeLoginCoupon');  // 登陆前点击领券留在本地的优惠券id
                const beforeLoginCoupon = JSON.parse(beforeLoginCouponStr);
                if (beforeLoginCoupon) {
                    // 如果本地存有优惠券id，则调用接口进行领券
                    this.getCoupon({
                        couponId: beforeLoginCoupon.couponId,
                        useType: beforeLoginCoupon.useType,
                        isGotCouponBeforeLogin: true,  // 指示调接口方法在处理登陆前调领券接口错误时，不跳转登陆。以免不想登陆的用户重复跳转登陆页
                    });
                    window.localStorage.removeItem('beforeLoginCoupon');
                }
            }
        });
    }

    fetchCoupons(payload) {
        $.ajax({
            url: '/coupons',
            type: 'GET',
        }).then((res) => {
            const list = res.data.couponList;

            this.setState({
                list,
            });
            if(payload && payload.callback) {
                payload.callback();
            }
        }).fail((err) => {
            // console.log('获取优惠券列表失败');
        });
    }

    // 生成隐藏领券通知计时器并返回
    getPopupTimer(cb) {
        return setTimeout(() => {
            // 3s 后隐藏领取成功提示
            this.setState({
                showGetCouponPopup: false,
            });
            if (Object.prototype.toString.call(cb) === "[object Function]") cb();
        }, 3000);
    }

    // 点击领取按钮接收到响应并传入/读取到登陆前领券保存到 localStorage 的数据时调用
    getCoupon(payload = {}) {
        // console.log(payload);
        const url = `/coupon/${payload.couponId}`;
        const postData = {
            couponId: payload.couponId,
        };

        $.ajax({
            url: url,
            type: 'POST',
            data: postData,
        }).then((res) => {
            const resStatus = res.status;
            // console.log(resStatus);
            switch (resStatus) {
                case 'success': {
                    this.handleGetCouponSuccess(Object.assign({}, payload, { res }));
                    break;
                }
                case 'fail': {
                    this.handleGetCouponFailOrErr(Object.assign({}, payload, { res }));
                    break;
                }
                case 'error': {
                    this.handleGetCouponFailOrErr(Object.assign({}, payload, { res }));
                    break;
                }
                default: {
                    this.setState({
                        showGetCouponPopup: true,
                        popupMsg: res.message || '领取失败',
                        popupTimer: this.getPopupTimer()
                    })
                }
            }
        }).fail(function (err) {
            console.log(err);
        }).always(function () {
            // console.log('结束请求领取优惠券');
        });
    }

    // 处理领券200返回状态
    handleGetCouponSuccess(payload) {
        const list = this.state.list;
        const couponData = payload.res && payload.res.data;  // 领取成功返回优惠券模型数据
        const today = new Date();
        const resStartDateStr = couponData.startDate;
        const spPos = resStartDateStr && resStartDateStr.indexOf(' ');  // 返回日期空格位置
        let startDateStr = resStartDateStr && resStartDateStr.substr(0, spPos);  // 返回日期去除时间
        startDateStr = startDateStr.replace(/\-/g, '/');  // 兼容IE/safari等，不能通过 '2019-01-01 00:00:00' 创建时间的浏览器
        const startDate = new Date(startDateStr + ' 00:00:00');  // 返回日期改为当天零点

        // alert(startDate)
        if (today < startDate) {
            // 活动未开始
            list.forEach((coupon, index) => {
                if (coupon.couponId === couponData.couponId) {
                    list[index].receiveStatus = NOT_START;
                }
            });
            const startDateStr = couponData.startDate && couponData.startDate.substr(0, 10);
            const endDateStr = couponData.endDate && couponData.endDate.substr(0, 10);


            this.setState({
                list,
                showGetCouponPopup: true,
                getCouponMsg: `领取成功! <br >${startDateStr} 至 ${endDateStr} 可使用~!`,
                popupTimer: this.getPopupTimer(),
            });
        } else {
            // 活动已开始，确定显示去使用弹窗还是vip无需再次购买弹窗
            this.handleGetVipCouponSuccess(payload);
        }
    }

    // 处理领券200, 且活动已开始，确定显示去使用弹窗还是vip无需再次购买弹窗
    handleGetVipCouponSuccess(payload) {
        const res = payload.res;
        const couponData = res && res.data;
        const list = this.state.list;

        // 修改本地列表数据，将该券改为已领取
        list.forEach((coupon, index) => {
            if (coupon.couponId === couponData.couponId) {
                list[index].receiveStatus = 'TO_USE';
                list[index].routeUri = couponData.routeUri;
            }
        });
        this.setState({
            showGetCouponPopup: true,
            getCouponMsg: '领取成功',
            popupTimer: this.getPopupTimer(),
            list,
        });
    }

    // 处理领券400返回状态
    handleGetCouponFailOrErr(payload) {
        const res = payload.res;
        const resCode = parseInt(res.code);
        const list = this.state.list;
        const couponId = payload.couponId;
        const isGotCouponBeforeLogin = payload.isGotCouponBeforeLogin;

        switch (resCode) {
            case 10001: {
                // 需要登陆

                if (!isGotCouponBeforeLogin) {
                    // 不是自动读取 localStorage 数据调用领券接口，而是手动点击立即领券按钮。

                    // 保存到本地等登陆后用于领券
                    // let localCoupon = window.localStorage.getItem('beforeLoginCoupon');
                    window.localStorage.setItem('beforeLoginCoupon', JSON.stringify({ couponId, useType: payload.useType }));

                    window.location.href = '/login';
                }
                break;
            }
            case 20001: {
                // 已抢光
                // console.log('已被抢光');
                // 修改本地列表数据，将该券改为已抢光
                list.forEach((coupon, index) => {
                    if (coupon.couponId === couponId) {
                        list[index].receiveStatus = NO_LEFT;
                    }
                });
                this.setState({
                    list,
                    showGetCouponPopup: true,
                    getCouponMsg: '你来晚了，优惠券被抢光了~',
                    popupTimer: this.getPopupTimer()
                });
                break;
            }
            case 20002: {
                // 领过期券
                // console.log('领过期券');
                window.location.reload();
                break;
            }
            case 20003: {
                // 已领取过
                this.setState({
                    showGetCouponPopup: true,
                    getCouponMsg: '你已经领取过啦~',
                    popupTimer: this.getPopupTimer()
                });
                break;
            }
            case 20004: {
                // 该券已在后台被删除
                // 重新调接口获取数据
                this.setState({
                    showGetCouponPopup: true,
                    getCouponMsg: '领取失败',
                    popupTimer: this.getPopupTimer(() => {
                        this.fetchCoupons();
                    })
                });
                // 重新取数据
                this.fetchCoupons();
                break;
            }
            default: {
                this.setState({
                    showGetCouponPopup: true,
                    getCouponMsg: res.message || '领取失败',
                    popupTimer: this.getPopupTimer()
                })
            }
        }
    }

    // 处理领券服务器返回500
    handleGetCouponServerErr(res) {
        this.setState({
            showGetCouponPopup: true,
            getCouponMsg: res.message || '领取失败',
            popupTimer: this.getPopupTimer(),
        });
    }

    // 计算可滑动总页数
    totalPages() {
        if (this.state.list) {
            return Math.ceil(this.state.list.length / 2);
        }
        return 1;
    }

    // 滑动列表后退
    handleBackoff() {
        this.setState({
            currPage: this.state.currPage - 1,
        });
    }

    // 滑动列表前进
    handleForward() {
        this.setState({
            currPage: this.state.currPage + 1,
        });
    }

    // 点击卡片立即使用按钮
    handleClickUse(routeUri, useType) {
        // console.log(routeUri, useType);
        const vipDiv = document.querySelector('.index-coupon-module-root-v');

        if (useType === 'VIP') {
            if (vipDiv) {
                // vip用户点击vip券立即使用，展示无需购买弹框
                this.setState({
                    showVipModal: true,
                });
            } else {
                let reg = /\/vip\/([0-9]{1,})/;
                let matchRs = reg.exec(routeUri);
                let packageId = matchRs ? matchRs[1] : '';
                // 普通用户购买vip
                let packageStr = `${packageId ? '&vipPackageId=' + packageId : ''}`

                window.location.href = `/secure/purchase?payType=vipPackage${packageStr}`;
            }
        } else {
            // 普通用户，跳转使用链接routeUri
            window.location.href = routeUri;
        }
    }

    // 关闭已是会员无需购买对话框
    handleClickCloseVipModal() {
        this.setState({
            showVipModal: false,
        });
    }

    render() {
        const children = [];
        const titleEl = document.querySelector('#home-module-coupon-title');
        const moduleTitle = titleEl && titleEl.innerText;
        const couponSectionTitle = new IndexSectionTitle({
            title: moduleTitle || '优惠活动',
            link: {
                address: '/secure/coupon-manage/coupon-list',
                name: '我的卡券包'
            }
        });
        const couponList = new CouponList({
            list: this.state.list,
            currPage: this.state.currPage,
            totalPages: this.totalPages(),
            handleClickUse: (routeUri, useType) => this.handleClickUse(routeUri, useType),
            handleClickReceiveBtn: this.getCoupon.bind(this),
            handleBackoff: this.handleBackoff.bind(this),
            handleForward: this.handleForward.bind(this),
        });
        const couponListIndicator = new CouponListIndicator({ totalPages: this.totalPages(), currPage: this.state.currPage });
        const popup = new Popup({ msg: this.state.getCouponMsg, show: this.state.showGetCouponPopup });
        const vipModal = new VipModal({
            handleClickCloseVipModal: () => this.handleClickCloseVipModal(),
            show: this.state.showVipModal,
        });
        children.push(couponSectionTitle);
        children.push(couponList);
        children.push(couponListIndicator);
        // children.push(couponModal);
        children.push(popup);
        children.push(vipModal);
        this.props.children = children;

        return `<div class="index-coupon-module">
            ${this.childRoot}
        </div>`
    }
}

export default CouponModule;