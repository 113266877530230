import $ from 'jquery';
import FakeReact from 'Utils/fake-react';
import CouponListPage from 'FakeReactComponents/mobile/home/CouponListPage/';
import BetterScroll from 'Libs/better-scroll/bscroll.min';
import './index.scss';

class CouponList extends FakeReact.Component {
    constructor(props) {
        // todo, 挂载子组件时类添加优化
        super(props);
    }

    eventHandler() {
        let that = this;
        let startX;
        let startY;
        let endX;
        let endY;
        let isTouchMove = true;
        let isPreventDefault = false;
        return {
            '.index-coupon-list-innerwrap': [
                {
                    type: 'touchstart',
                    handler(e) {
                        // 获取初始位置
                        startX = e.touches[0].clientX;
                        startY = e.touches[0].clientY;
                        // 记录是否进入过touchMove
                        isTouchMove = true;
                    }
                },
                {
                    type: 'touchmove',
                    handler(e) {
                        // 如果是UC浏览器 并且第一次滑动执行时  
                        if (isTouchMove) {
                            endX = e.touches[0].clientX;
                            endY = e.touches[0].clientY;
                            // 判断滑动方向  横向 | 垂直
                            if (Math.abs(endX - startX) > Math.abs(endY - startY)) {
                                // 如果滑动为横向，那么禁止所有默认事件
                                // 必要！否则会影响纵向页面的滚动
                                e.preventDefault()
                                // 用来辅助判断是否已经禁止过默认事件
                                isPreventDefault = true;
                            }
                            // 关闭处理逻辑  touchMove连续触发就没必要再进来了
                            isTouchMove = false;
                        }
                    }
                }, {
                    type: 'touchend',
                    handler(e) {
                        // 如果已经禁止过默认事件  那么恢复默认事件
                        if (isPreventDefault && e.preventDefault) {
                            // 恢复默认事件
                            e.preventDefault()
                            isPreventDefault = false;
                        }
                    }
                }
            ]
        };
    }

    componentDidMount() {
        if (this.el) {
            try {
                const betterScroll = new BetterScroll(this.el, {
                    scrollX: true, // 滚动方向
                    scrollY: false,
                    momentum: false, // 当快速滑动时是否开启滑动惯性
                    eventPassthrough: 'vertical',
                    stopPropagation: true,
                    observeDOM: false,
                    snap: {
                        speed: 400
                    },
                    tap: true, // 是否派发click事件
                });

                betterScroll.goToPage(this.props.currPage, 0, 0);  // 根据父组件状态决定初始状态在哪一页
                // console.log(`组件当前: ${this.props.currPage}, bs当前：${betterScroll.getCurrentPage().pageX}`);

                const _scrollEnd = (data) => {
                    let direction;
                    // console.log(betterScroll.getCurrentPage().pageX, this.props.currPage, this.props.totalPages)

                    if (betterScroll.getCurrentPage().pageX - this.props.currPage > 0) {
                        direction = 'forward';
                    } else if (betterScroll.getCurrentPage().pageX - this.props.currPage < 0) {
                        direction = 'backoff';
                    }

                    if (direction === 'forward') {
                        if (this.props.currPage < this.props.totalPages - 1) {
                            // console.log('前进\n\n')
                            this.props.handleForward();
                        }
                    } else if (direction === 'backoff') {
                        if (this.props.currPage > 0) {
                            // console.log('后退\n\n');
                            this.props.handleBackoff();
                        }
                    }
                }
                // 这里 better-scroll 会派发两次 scrollEnd事件，所以只能监听 touchEnd 并在监听器监听一次 scrollEnd 事件
                betterScroll.on('touchEnd', () => betterScroll.once('scrollEnd', _scrollEnd));
                this.betterScroll = betterScroll;
            } catch (e) {
            }
        }
    }

    isUC() {
        // 判断是否UC浏览器
        return navigator.appVersion.indexOf('baidubrowser') !== -1
    }

    deviceWidth() {
        return window.innerWidth;
    }

    render() {
        const children = [];
        const list = this.props.list;
        let pageNum = 0;
        let deviceWidth = window.innerWidth;
        // console.log(this.props.list);
        // 每两个卡片一页
        for (let i = 0; i < list.length; i += 2) {
            let couponPageProps = [];
            couponPageProps.push(list[i]);
            if (list[i + 1]) {
                couponPageProps.push(list[i + 1]);
            }
            pageNum++;
            children.push(new CouponListPage({
                coupons: couponPageProps,
                handleClickUse: this.props.handleClickUse,
                handleClickReceiveBtn: this.props.handleClickReceiveBtn
            }));
        }
        this.props.children = children;

        return `<div class="index-coupon-list" style="width: ${deviceWidth}px;">
            <div class="index-coupon-list-innerwrap" style="width: ${pageNum * deviceWidth}px;">
            ${this.childRoot}
            </div>
        </div>`;
    }
}

export default CouponList;