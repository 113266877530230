import FakeReact from 'Utils/fake-react';
import './index.scss';

class Modal extends FakeReact.Component {
    constructor(props = {}) {
        super(props);
    }

    eventHandler() {
        let that = this;
        return {
            '.mobile-modal-bg': {
                type: 'click',
                handler() {
                    if(that.props.close) {
                        that.props.close();
                    }
                }
            }
        };
    }

    render() {
        let styleStr = ``;
        if(this.props.style) {
            let style = this.props.style;
            if(style && style.height) {
                const height = style.height;
                if(height) {
                    styleStr += `height: ${height.number}${height.unit};`;
                }
            }
        }

        return `<div class="mobile-modal-wrap" style="display: ${this.props.show ? 'block' : 'none'};">
            <div class="mobile-modal-bg"></div>

            <div class="mobile-modal" style="${styleStr}">
                ${this.childRoot}
            </div>
        </div>`;
    }
}

export default Modal;