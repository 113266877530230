/*
* tab切换插件
*/

/*
* @param {Object} param 以控制栏中 data-in(data-out) 属性绑定的属性名
  作为属性传入 Object 中，属性为 Function;
*/

$.fn.tab = function(param = {}) {

  /*
  * @param {Element} ele tab控制标签
  * @param {String} type 需要获取的函数类型 data-in(进入标签执行函数) 或 data-out(反之)
  * @return {Function} 切换标签执行的函数
  */
  const checkFunc = function(ele, type) {
    let func = function(){};
    const dataIn = $(ele).attr(type);
    if(dataIn && param[dataIn]) {
       func = param[dataIn]
    }

    return func;
  }

  let funcin = function(){};
  let funcout = function(){};

  // tab选择标签
  const $list = this.find('[data-index]');
  const $contain = $('#' + this.attr('data-tab'));

  // tab内容
  const $containList = $contain.children();
  const len = $list.length;
  const percent = 1 / len * 100 + '%';

  // 初始化是标签顺序为 0
  let current = 0;

  let $currentList = $list.eq(current);

  let minHeight = window.innerHeight - $contain.offset().top;

  funcin = checkFunc($currentList, 'data-in');
  funcout = checkFunc($currentList, 'data-out');

  // 为当前内容添加 class active
  $currentList.addClass('active');
  $containList.eq(current).addClass('active');

  // 内容父级需要设置高度为当前内容高度，再配合overflow: hidden

  $contain.css({
    height: Math.max($containList.eq(current).outerHeight(), minHeight)
  });

  if(param.afterClass)  $containList.eq(current).addClass(param.afterClass);

  // 等分选择标签与内容
  $list.css({
    width: percent,
    opacity: 1
  });
  $contain.css({
    width: len * 100 + '%'
  });
  $containList.css({
    width: percent
  });

  $contain.css('visibility', 'visible');

  this.on('click', '[data-index]', function(event) {
    // 获取点击标签的顺序
    const index = $(this).attr('data-index') / 1 - 1;

    const $currentList = $list.eq(index);

    // 如果点击的为当前显示内容则忽略
    if(current !== index) {
      funcout();
      funcin = checkFunc($currentList, 'data-in');
      funcout = checkFunc($currentList, 'data-out');
      funcin();

      // 切换class
      const $old = $containList.eq(current);
      const $new = $containList.eq(index);

      $list.eq(current).removeClass('active');
      $currentList.addClass('active');

      $old.removeClass('active');
      $new.addClass('active');
      current = index;
      if(param.afterClass) {
        setTimeout(()=> {
          $old.removeClass(param.afterClass);
          $new.addClass(param.afterClass);
        }, 0);
      }
      const $item = $containList.eq(current);
      const height = $item.outerHeight();
      const per = current * (1 / len) * 100 * -1 + '%';
      $item.addClass('active');
      $contain.css({
        height: Math.max(height, minHeight),
        transform: 'translate3d(' + per + ',0,0)',
        '-webkit-transform':  'translate3d(' + per + ',0,0)'
      });

    }
  });

  return {

    /*
    * 若当前显示内容有元素添加使高度增加，需要调用此方法
    */
    refresh() {
      $contain.css({
        height: Math.max($containList.eq(current).outerHeight(), minHeight)
      })
    }
  }
}
